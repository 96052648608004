$highlight: #612351;
$accent: #00817a;

html, body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #1D1D1D;
}

header {
  padding: 12px 24px;
  color: white;
  background-color: $highlight;
  font-weight: bold;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.video-streamer {
  width: 300px;
  position: relative;
  margin-bottom: 20px;;
  background:black;
  overflow: hidden;
  border-radius: 14px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
  &:after {
    display: block;
    content: "";
    padding-bottom: 75%;
    width: 100%;
  }
  &__label {
    position: absolute;
    background: rgba(#612351, 0.8);
    padding: 3px 8px;
    color: white;
    bottom: 3px;
    right: 3px;
    font-size: 0.8rem;
    z-index: 1;
    font-weight: bold;
    border-radius: 11px;
  }
  &__controls {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    appearance: none;
    color: white;
    padding: 3px;
    background-color: rgba(161, 46, 176, 0.8);
    width: 31px;
    height: 29px;
    border-radius: 11px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 3px;
    &--audio {
      left: 0;
    }
    &--video {
      left: 33px;
    }
    &--remote {
      pointer-events: none;
      display: none;
      &.video-streamer__controls--disabled {
        display: flex;
      }
    }
    &--disabled {
      background-color: red;
      &:after {
        position: absolute;
        content: "";
        background: white;
        width: 70%; 
        height: 2px;
        transform: rotateZ(45deg);
      }
      &:before {
        position: absolute;
        content: "";
        background: red;
        width: 70%; 
        height: 4px;
        transform: rotateZ(45deg);
      }
    }
  }
  video {
    position: absolute;
    width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;  
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.welcome {
  &__wrapper {
    padding: 22px 28px;
  }
  &__header {
    margin-top: 0;
    color: $highlight;
  }
}

.input-group {
  margin-bottom: 20px;
  display: inline-block;
  font-size: 1.2rem;
  input, textarea, select {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    margin-left: 10px;
  }
}

.button-primary {
  background-color: $highlight;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: white;
  font-size: 1.3rem;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
  border-radius: 9px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0);
  transition: 0.3s ease;
  &.disabled {
    cursor: not-allowed;
    background-color: grey;
  }
  &:not(.disabled) {
    cursor: pointer;
    &:hover, &:focus {
      transform: translateY(-2px);
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    }
  }
  &--space {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.game-container {
  width: 50%;
  padding: 24px;
  overflow: scroll;
  border-right: 5px solid $highlight;
}

.game-wrapper {
  display: flex;
  height: calc( 100vh - 52px );
}

* {
  box-sizing: border-box;
}

.stream-container {
  padding: 0 12px 12px;
  margin: auto;
  width: 50%;
  height: calc( 100vh - 52px );
  &__inner {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    max-height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    .video-streamer {
      flex-shrink: 1;
      margin-top: 12px;
      margin-bottom: 0px;
      flex-basis: calc( 50% - 6px );
      // height: calc( (100vh - 64px)/4 - 12px )
    }
  }
}

.game-card {
  box-sizing: content-box;
  display: flex;
  height: 284px;
  width: 314px;
  background: $highlight;
  border: 10px solid $accent;
  border-right: 82px solid $accent;
  position: relative;
  &--with-answers {
    cursor: pointer;
  }
  &__front-cap {
    width: 62px;
    padding: 5px 5px 12px 5px;
  }
  &__end-cap {
    transform: rotateZ(-1.2deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: -78px;
    width: 72px;
  }
  &__list {
    list-style: none;
    color: white;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    position: relative;
    padding-left: 5px;
    &--questions {
      text-transform: uppercase;
      .game-card__list-item {
        font-size: 15px;
      }
    }
    &--answers {
      .game-card__list-item {
        font-size: 12px;
      }
    }
    .game-card__list-item {
      position: absolute;
      width: 230px;
      transform: translateY(-50%);
      @for $i from 1 to 6 {
        &:nth-child(#{$i}) {
          top: 21px + ($i - 1) * 53px;
        }
      }
    }
  }
}

.answer-box {
  width: 100%;
  height: 80px;
  resize: vertical;
}

.answer {
  &__title {
    margin-bottom: 5px;
  }
  &__text {
    margin-top: 5px;
  }
}

select, input, textarea { font-size: 1rem; }